<div class="card" style="padding: 24px 36px 32px;gap: 20px">
  <div class="d-flex align-self-center">
    <mat-icon [svgIcon]="icon" style="height: 36px; width: 36px"></mat-icon>
  </div>
  <div class="d-flex flex-column" style="gap:32px">
    <div class="d-flex flex-column align-items-center" style="gap: 16px">
      <h5>{{ title}}</h5>
      <div style="text-align:center">
        {{ textContent }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button (click)="dialogRef.close()" class="sp-btn sp-btn-secondary">
        Close
      </button>
    </div>
  </div>
</div>
