import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {KeyboardUtils} from '../../../../../utils/keyboard/keyboard-utils';
import {
  FACEBOOK_GE0_SUPPORT,
  FbCriteria,
  FbGeolocation,
  FbGeolocationGroup,
  FbLocale,
  FbRelationshipStatus
} from '../../../../../types/facebook-types';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {
  AudienceInfo,
  AudienceType,
  CriteriaField,
  GeoField,
  ProjectCreationManagerService,
  TargetingType
} from '../../../../../services/sp-project/project-creation-manager.service';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AudienceImportType, FacebookLinkService} from '../../../../../services/sp-facebook/facebook-link.service';
import {LoggerService} from '../../../../../services/sp-logger/logger.service';
import {debounceTime, switchMap} from 'rxjs/operators';
import {Targeting} from '../../../../../classes/targeting/targeting';
import {ChartService} from '../../../../../services/sp-chart/chart.service';
import {Audience} from '../../../../../models/audience';
import {ApiUserService} from '../../../../../services/sp-api/sp-api-user/api-user.service';
import {AuthenticationService} from '../../../../../services/sp-authentication/authentication.service';
import {AdAccount} from '../../../../../models/ad-account';
import {AlertService} from '../../../../../services/sp-alert/alert.service';
import {Universe} from '../../../../../models/universe';
import {ApiUniverseService} from '../../../../../services/sp-api/sp-api-universe/api-universe.service';
import {environment} from '../../../../../../environments/environment';
import {RoleUtils} from '../../../../../utils/role/role-utils';
import {TargetingUtils} from '../../../../../utils/targeting/targeting-utils';

export interface AudienceSetupOptions {
  allowAudienceImport: boolean;
  allowGeolocationEditing: boolean;
  allowSociodemoOptions: boolean;
  importFieldName?: string;
  importAudienceTypes?: AudienceImportType[];
}

export interface ProjectSetupOptions {
  universeNameFallback?: string;
}


@Component({
  selector: 'app-sp-project-create-audience-setup',
  templateUrl: './sp-project-create-audience-setup.component.html',
  styleUrls: ['./sp-project-create-audience-setup.component.scss']
})
export class SpProjectCreateAudienceSetupComponent implements OnInit, OnDestroy {
  universeControl: UntypedFormControl = new UntypedFormControl('');
  // View related
  @Input() options: AudienceSetupOptions;
  showMoreOptions: boolean = false;
  //. ---

  // Form controls
  toppings: UntypedFormControl = new UntypedFormControl('');
  //. ---

  // Age slider related
  ageSliderChange: Subject<number[]> = new Subject<number[]>();
  private ageSliderSubscription: Subscription;
  //. ---

  // Input related
  geolocations: FbGeolocation[] = [];
  criterion: FbCriteria[] = [];
  locales: FbLocale[] = [];
  relationshipStatuses: FbRelationshipStatus[] = [];
  searchText = '';
  private lastCriteriaName: string;
  private geolocationInputChange: Subject<string> = new Subject<string>();
  private geolocationInputSubscription: Subscription;
  private criteriaInputChange: Subject<string> = new Subject<string>();
  private criteriaInputSubscription: Subscription;
  private localeInputChange: Subject<string> = new Subject<string>();
  private localeInputSubscription: Subscription;
  private relationshipStatusInputChange: Subject<string> = new Subject<string>();
  private relationshipStatusSubscription: Subscription;
  // . ---

  // Facebook related
  private sizeSource: string;

  // Audience related
  audiencesLoaded: boolean = false;
  audienceSelected: boolean = false;
  importedAudiences: Array<Audience> = [];
  //. ---
  freemium = false;

  selectedUniverse: Universe;
  selectedUniverses: Universe[] = [];
  universes: Universe[] = [];

  constructor(
    public creationManager: ProjectCreationManagerService,
    private fb: FacebookLinkService,
    private logger: LoggerService,
    private apiUser: ApiUserService,
    private auth: AuthenticationService,
    private alert: AlertService,
    private apiUniverse: ApiUniverseService
  ) {
  }

  @Input() private readonly optionsUniverse: ProjectSetupOptions;

  async ngOnInit() {
    if (this.options.allowAudienceImport) {
      this.apiUser.getOne(this.auth.session.user.id).then(user => {
        const adAccounts = user.ad_accounts;
        this.importAllAudiences(adAccounts).then(audiences => {
          audiences.forEach(a => {
            switch (this.sizeSource) {
              case 'original':
                if (!a.fb_size) {
                  a.fb_size = a.fb_size_lower;
                }
                break;
              case 'lower':
                a.fb_size = a.fb_size_lower;
                break;
              case 'upper':
                a.fb_size = a.fb_size_upper;
                break;
              case 'average':
                a.fb_size = Math.round((a.fb_size_lower + a.fb_size_upper) / 2);
                break;
              default:
                a.fb_size = a.fb_size_lower;
            }
          });
          this.importedAudiences = audiences;
        }).catch(error => {
          if (error.code && error.error_subcode) {
            if (error.code == 190 && error.error_subcode == 463) {
              this.alert.notify('Ad Account error', 'It seems that your Facebook token has expired, go to "My Profile" and reconnect your Ad accounts', 'error');
            }
          }
        }).finally(() => this.audiencesLoaded = true);
      });
    }
    if (this.options.allowGeolocationEditing) {
      this.apiUser.getParam('source_type').then(param => {
        this.sizeSource = param.value;
        this.logger.debug('Source type param ' + this.sizeSource + ' loaded');
      }).catch(err => {
        this.logger.logWarning('Could not load source_type param, setting lower by default');
        this.logger.logError(err);
        this.sizeSource = 'lower';
      });
    }
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
    }
    if (this.freemium) {
      this.apiUniverse.getAll().then((u: Universe[]) => {
        this.universes = u.filter(u => !u.default);
        let universeDefault = this.universes.find(u => u.default_select);
        if (!universeDefault) {
          universeDefault = this.universes.find(u => u.name === 'Worldwide');
        }
        if (universeDefault) {
          this.selectedUniverse = universeDefault;
          this.onUniverseSelected(universeDefault);
        }
      });
    }
    this.geolocationInputSubscription = this.geolocationInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== '') {
          this.fb.searchGeolocation(query).then(geolocations => {
            this.geolocations = geolocations.filter(g => FACEBOOK_GE0_SUPPORT.includes(g.type));
          });
        } else {
          this.geolocations = [];
        }
        return EMPTY;
      })
    ).subscribe();
    this.criteriaInputSubscription = this.criteriaInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== '') {
          this.fb.searchFbInterest(query).then(criterion => {
            const allCriterion: FbCriteria[] = this.getAllSelectedCriterion();
            this.criterion = criterion.filter(c => {
              return !allCriterion.find(cr => cr.id == c.id);
            });
          });
        } else {
          this.criterion = [];
        }
        return EMPTY;
      })
    ).subscribe();
    this.localeInputSubscription = this.localeInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== '') {
          this.fb.searchLocale(query).then(locales => {
            this.locales = locales;
          });
        } else {
          this.locales = [];
        }
        return EMPTY;
      })
    ).subscribe();

    this.relationshipStatusSubscription  = this.relationshipStatusInputChange.pipe(
      debounceTime(500),
      switchMap(searchQuery => {
        const query = searchQuery.trim();
        if (query !== '') {
          const searchResult = TargetingUtils.searchByType('relationship_statuses', query);
          let result: FbRelationshipStatus[] = [];
          searchResult?.forEach(status => {
            result.push({ key: status.key, name: status.name })
          })
          this.relationshipStatuses = result;
        } else {
          this.relationshipStatuses = [];
        }
        return EMPTY;
      })
    ).subscribe();
    this.ageSliderSubscription = this.ageSliderChange.pipe(
      debounceTime(500),
      switchMap(() => {
        this.updateAges();
        return EMPTY;
      })
    ).subscribe();
    this.creationManager.audienceTypeSwitchSubject.subscribe(() => {
      this.loadAudienceInfo(this.currentInfo, true);
    });

    const universes = <Universe[]>(await this.apiUniverse.getAll());
    this.universes = universes.filter(u => !u.default);
    let universeDefault = this.universes.find(u => u.default_select);

    if (!universeDefault && this.optionsUniverse?.universeNameFallback) {
      universeDefault = this.universes.find(u => u.name === this.optionsUniverse.universeNameFallback);
    }
    if (universeDefault) {
      this.selectedUniverse = universeDefault;
      this.onUniverseSelected(universeDefault);
    } else if (this.universes.length > 0) {
      this.selectedUniverse = this.universes[0];
      this.onUniverseSelected(this.universes[0]);
    }
  }

  ngOnDestroy() {
    if (this.geolocationInputSubscription) {
      this.geolocationInputSubscription.unsubscribe();
    }
    if (this.criteriaInputSubscription) {
      this.criteriaInputSubscription.unsubscribe();
    }
    if (this.localeInputSubscription) {
      this.localeInputSubscription.unsubscribe();
    }
    if (this.relationshipStatusSubscription) {
      this.relationshipStatusSubscription.unsubscribe();
    }
    if (this.ageSliderSubscription) {
      this.ageSliderSubscription.unsubscribe();
    }
  }

  private loadAudienceInfo(audienceInfo: AudienceInfo, fromTargeting: boolean = false) {
    this.resetInfo();
    if (fromTargeting) {
      this.creationManager.loadAudienceInfoFromTargeting(this.currentAudienceSpec, this.audienceType);
    }
    if (audienceInfo.excludedGeolocations.length > 0 && !this.excludedGeolocationField) {
      this.toggleGeolocationExclusion();
    }
    if (audienceInfo.includedCriterion.size > 0) {
      this.includedCriteriaFields = [];

      audienceInfo.includedCriterion.forEach((val, key) => {
        key.criteria = undefined; // Resetting to undefined to avoid ghost values to show
        this.includedCriteriaFields.push(key);
      });
    }
    if (audienceInfo.excludedCriterion.length > 0 && !this.excludedCriteriaField) {
      this.toggleCriteriaExclusion();
    }
  }

  private resetInfo() {
    this.criterion = [];
    this.geolocations = [];
    this.locales = [];

    this.creationManager.resetAudienceFields(this.audienceType);
  }

  /**
   * Importation of all audiences
   * @param adAccounts
   */
  private async importAllAudiences(adAccounts: Array<AdAccount>): Promise<Array<Audience>> {
    if (adAccounts.length == 0) {
      return [];
    }

    let allAudiences: Array<Audience> = [];
    if (this.options.importAudienceTypes) {
      if (this.options.importAudienceTypes.length == 0) {
        return [];
      }
      for (const type of this.options.importAudienceTypes) {
        try {
          const audiences = await this.fb.importAudiences(adAccounts, type);
          allAudiences = allAudiences.concat(audiences).sort(function(a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        } catch (err) {
        }
      }

      return allAudiences;
    } else {
      return [];
    }
  }

  /* ---------------------------------------------
   * String conversions
   */
  criteriaToString(criteria: FbCriteria) {
    const interestSize = (fbInterest: FbCriteria) => {
      switch (this.sizeSource) {
        case 'upper':
          return ChartService.separator(fbInterest.audience_size_upper_bound);
        case 'lower':
          return ChartService.separator(fbInterest.audience_size_lower_bound);
        case 'both':
          return ChartService.separator(fbInterest.audience_size_lower_bound) + ' to ' + ChartService.separator(fbInterest.audience_size_upper_bound);
        case 'average':
          return ChartService.separator(Math.round((fbInterest.audience_size_lower_bound + fbInterest.audience_size_upper_bound) / 2));
        case 'original':
          return fbInterest.audience_size ? ChartService.separator(fbInterest.audience_size) : ChartService.separator(fbInterest.audience_size_lower_bound);
        default:
          return ChartService.separator(fbInterest.audience_size_lower_bound);
      }
    };

    if (!criteria) {
      return '';
    }
    const name = criteria.name;
    const size = criteria.audience_size;
    const type = criteria.type;

    const sizeInfo = size > 0 ? (' - ' + interestSize(criteria) + ' people') : '';

    return name + sizeInfo + ' (' + type + ')';
  }

  localeToString(locale: FbLocale) {
    return locale ? locale.name : undefined;
  }

  relationshipStatusToString(relStatus: FbRelationshipStatus) {
    return relStatus ? relStatus.name : undefined;
  }

  // ---------------------------------------------

  /**
   * Update ages of the current audience based on "ages" array bound with the slider
   */
  updateAges() {
    this.updateAudienceTargetingAndSize(true);
  }

  updateGenders(genders: number[]) {
    this.currentInfo.genders = genders;
    this.updateAudienceTargetingAndSize(true);
  }

  toggleGeolocationExclusion() {
    if (this.excludedGeolocationField) {
      this.excludedGeolocationField = null;
      this.excludedGeolocations = [];

      this.updateAudienceTargetingAndSize(true);
    } else {
      this.excludedGeolocationField = new GeoField();
    }
  }

  toggleCriteriaExclusion() {
    if (this.excludedCriteriaField) {
      this.excludedCriteriaField = null;
      this.excludedCriterion = [];

      this.updateAudienceTargetingAndSize(true);
    } else {
      this.excludedCriteriaField = new CriteriaField();
    }
  }

  showOptions() {
    this.showMoreOptions = true;
  }

  hideOptions() {
    this.showMoreOptions = false;
  }

  geolocToString(geolocation: FbGeolocation) {
    return this.creationManager.geolocToString(geolocation);
  }

  addGeolocation(geoloc: FbGeolocation, type: TargetingType) {
    this.creationManager.addGeolocation(geoloc, type).catch(err => this.handleFacebookError(err));
  }

  /**
   * Add an inclusion criteria field to the form
   */
  addIncludeCriteriaField() {
    const field = new CriteriaField();
    this.includedCriteriaFields.push(field);
  }

  /**
   * Remove an inclusion criteria field from the form
   * @param field The field to remove
   */
  removeIncludeCriteriaField(field: CriteriaField) {
    this.includedCriterion.delete(field);
    this.includedCriteriaFields.splice(this.includedCriteriaFields.indexOf(field), 1);

    this.updateAudienceTargetingAndSize(true);
  }

  getAllSelectedCriterion(): FbCriteria[] {
    let allCriterion: FbCriteria[] = [];
    Array.from(this.includedCriterion.values()).forEach(criterion => {
      allCriterion = allCriterion.concat(criterion);
    });
    return allCriterion.concat(this.excludedCriterion);
  }

  // Events
  /**
   * Geolocation deletion event trigger
   * Used to delete a previously selected geolocation
   */
  onGeolocationDeleted(value: FbGeolocation, type: TargetingType) {
    this.creationManager.deleteGeolocation(value, type).catch(err => this.handleFacebookError(err));
  }

  onGeolocationGroupDeleted(value: FbGeolocationGroup) {
    this.creationManager.deleteGeolocationGroup(value).catch(err => this.handleFacebookError(err));
  }

  /**
   * Event for when a geolocation input is updated
   * The update is delayed to avoid spamming Facebook API
   * @param query
   * @param event
   */
  onGeolocationInputChange(query: string) {
    this.geolocations = [null];
    this.geolocationInputChange.next(query);
  }

  /**
   * Geolocation selection event trigger
   * Used to flush the list of geolocations when the user made his choice
   */
  onGeolocationSelected(value: FbGeolocation, type: TargetingType) {
    this.creationManager.addGeolocation(value, type).catch(err => this.handleFacebookError(err));
  }

  onLocaleInputChange(query: string) {
    this.locales = [null];
    this.localeInputChange.next(query);
  }

  onLocaleSelected(value: FbLocale) {
    if (!this.includedLocales.find(l => l.key == value.key)) {
      this.includedLocales.push(value);

      this.updateAudienceTargetingAndSize(true);
    }
  }

  onLocaleDeleted(value: FbLocale) {
    this.includedLocales.splice(this.includedLocales.indexOf(value), 1);

    this.updateAudienceTargetingAndSize(true);
  }

  onRelationshipStatusInputChange(query: string) {
    this.relationshipStatuses = [null];
    this.relationshipStatusInputChange.next(query);
  }

  onRelationshipStatusSelected(value: FbRelationshipStatus) {
    if (!this.includedRelationshipStatus.find(l => l.key === value.key)) {
      this.includedRelationshipStatus.push(value);

      this.updateAudienceTargetingAndSize(true);
    }
  }

  onRelationshipStatusDeleted(value: FbRelationshipStatus) {
    this.includedRelationshipStatus.splice(this.includedRelationshipStatus.indexOf(value), 1);

    this.updateAudienceTargetingAndSize(true);
  }

  onCriteriaInputChange(query: string) {
    this.criterion = [null];
    this.criteriaInputChange.next(query);
  }

  closePanelIfShowingSuggestions(trigger: MatAutocompleteTrigger, criteriaInput: HTMLInputElement) {
    if (this.displayedOptionsAreSuggestions(criteriaInput, trigger)) {
      trigger.closePanel();
    }
  }

  resetCriterionIfShowingSuggestions(trigger: MatAutocompleteTrigger, criteriaInput: HTMLInputElement) {
    if (this.displayedOptionsAreSuggestions(criteriaInput, trigger)) {
      this.criterion = [];
    }
  }

  displayedOptionsAreSuggestions(criteriaInput: HTMLInputElement, trigger: MatAutocompleteTrigger) {
    // We reset the criteriaInput when suggestions are opened.
    // Therefore, if the criteriaInput is empty and the panel is open, it means that the suggestions are opened
    return criteriaInput.value.length === 0 && trigger.panelOpen;
  }

  suggestionButtonDisabled() {
    const includedCriteriaField = this.includedCriteriaFields[0];
    const includedCriterion = includedCriteriaField ? this.includedCriterion.get(includedCriteriaField) : undefined;
    return includedCriterion ? includedCriterion.length === 0 : true;
  }

  /**
   * Criteria selection event trigger
   * Used to flush the list of criterion when the user made his choice
   * and to add the criteria to the list
   */
  onCriteriaSelected(field: CriteriaField, type: TargetingType) {
    this.criterion = [];
    if (!field.criteria) {
      this.lastCriteriaName = undefined;
      return;
    }

    this.creationManager.addCriteria(field.criteria, field, type).catch(err => this.handleFacebookError(err));
  }

  fetchSuggestions(trigger: MatAutocompleteTrigger, criteriaInput: HTMLInputElement = null) {
    const includedInterestsLength = this.includedCriterion.get(this.includedCriteriaFields[0]).length;
    if (includedInterestsLength === 0) {
      return;
    }

    // TODO: Consider defining this.lastCriteriaName like this in the first place
    this.lastCriteriaName = this.includedCriterion.get(this.includedCriteriaFields[0])[includedInterestsLength - 1].name;

    if (criteriaInput) {
      criteriaInput.value = '';
    }
    this.criterion = [null];
    this.fb.interestSuggestion(this.lastCriteriaName).then(criterion => {
      criterion.forEach(c => {
        if (!c.audience_size_lower_bound) {
          c.audience_size_lower_bound = c.audience_size;
        }
        if (!c.audience_size_upper_bound) {
          c.audience_size_upper_bound = c.audience_size;
        }
      });
      const allCriterion: FbCriteria[] = this.getAllSelectedCriterion();
      this.criterion = criterion.filter(c => {
        c.type = 'interests';
        return c.path && c.path.length > 0 && c.path[0].toLowerCase() == 'interests' && !allCriterion.find(cr => cr.id == c.id);
      });
      if (this.criterion.length == 0) {
        this.criterion = [undefined];
      }
      trigger.openPanel();
    });
  }

  /**
   * Criteria deletion event trigger
   * Used to delete a previously selected criteria
   */
  onCriteriaDeleted(field: CriteriaField, value: FbCriteria, type: TargetingType) {
    if (type == TargetingType.INCLUSION) {
      if (!field) {
        this.logger.logError('Criteria delete: the field must be specified');
        return;
      }

      const array = this.includedCriterion.get(field);
      array.splice(array.indexOf(value), 1);
    } else if (type == TargetingType.EXCLUSION) {
      this.excludedCriterion.splice(this.excludedCriterion.indexOf(value), 1);
    }

    this.updateAudienceTargetingAndSize(type == TargetingType.INCLUSION);
  }

  onPlatformUpdate() {
    this.updateAudienceTargetingAndSize(true);
  }

  /**
   * Handle event where an imported audience is selected
   * @param audience
   */
  onAudienceImportSelectChange(audience: Audience) {
    this.audienceSelected = true;
    const selectedUniverses = [...this.creationManager.audienceTarget.universes]; // Array copy of universes

    if (this.audienceType === AudienceType.target) {
      this.creationManager.changeProjectName(audience.name.substring(0, 25));
    }

    // Setting target
    this.creationManager.pushAudienceType(audience, this.audienceType);
    this.creationManager.markCurrentAsImported();
    const audienceTarget = this.creationManager.audienceTarget;

    this.loadAudienceInfo(this.currentInfo, true);

    // Setting benchmark if linked
    if (this.creationManager.targetBenchmarkLink && this.audienceType === AudienceType.target) {
      this.setBenchmarkAudienceBasedOnTargetAudience(audienceTarget);
    }
    this.creationManager.setProjectUniverses(selectedUniverses);
    this.updateAudienceTargetingAndSize(this.targetBenchmarkLink);
  }


  private setBenchmarkAudienceBasedOnTargetAudience(audienceTarget: Audience) {
    const audienceBenchmark = new Audience();
    const benchmarkSpec = this.creationManager.audienceTarget.targeting.sociodemoCopy();

    audienceBenchmark.name = audienceTarget.name + ' - Benchmark';
    audienceBenchmark.type = 'sociodemo';
    audienceBenchmark.locale = 'en_US';
    audienceBenchmark.target_spec = benchmarkSpec.toFbJsonString();

    this.creationManager.pushAudienceType(audienceBenchmark, AudienceType.benchmark);
  }

  /**
   * Event for when a universe is selected
   * @param universe
   */
  onUniverseSelected(universe: Universe) {
    this.creationManager.setProjectUniverses([universe], true);
  }

  /// Testing things
  universeOrder() {
    this.universes.sort((a, b) => a.name.localeCompare(b.name));
    return this.universes;
  }

  resetSearchInput() {
    this.searchText = '';

    let widthSelect = document.getElementById('sp-select-universe').offsetWidth;
    const elems = document.getElementsByClassName('mat-select-panel-wrap') as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < elems.length; i++) {
      elems[i].style.minWidth = widthSelect + 'px !important';

      elems[i].style.width = 'calc(' + widthSelect + 'px - 32px)';
    }
  }

  private updateAudienceTargetingAndSize(updateBenchmark: boolean = false) {
    this.creationManager.updateCurrentAudience(updateBenchmark).catch(err => this.handleFacebookError(err));
  }

  private handleFacebookError(err: any) {
    const fbError = err.error;
    if (fbError && fbError.error && fbError.error.code == 100) {
      const msg = fbError.error.error_user_msg ? fbError.error.error_user_msg : fbError.error.message;
      const title = fbError.error.error_user_title ? fbError.error.error_user_title : 'Audience size update error';
      this.alert.notify(title, msg, 'error');
    }
  }

  /*
   * Getters & Setters
   */
  get audienceTarget() {
    return this.creationManager.audienceTarget;
  }

  get audiencesInfo() {
    return this.creationManager.audiencesInfo;
  }

  get currentInfo(): AudienceInfo {
    return this.audiencesInfo.get(this.audienceType);
  }

  get audienceType(): AudienceType {
    return this.creationManager.audienceType;
  }

  get includedGeolocations() {
    return this.currentInfo.includedGeolocations;
  }

  get includedGeolocationGroups() {
    return this.currentInfo.includedGeolocationGroups;
  }

  get includedGeolocationField(): GeoField {
    return this.creationManager.audiencesInfo.get(this.audienceType).includedGeolocationField;
  }

  get TargetingType(): typeof TargetingType {
    return TargetingType;
  }

  get AudienceInfo(): typeof AudienceInfo {
    return AudienceInfo;
  }

  get excludedGeolocationField(): GeoField {
    return this.creationManager.audiencesInfo.get(this.audienceType).excludedGeolocationField;
  }

  set excludedGeolocationField(field: GeoField) {
    this.creationManager.audiencesInfo.get(this.audienceType).excludedGeolocationField = field;
  }

  set excludedGeolocations(geo: FbGeolocation[]) {
    this.currentInfo.excludedGeolocations = geo;
  }

  get excludedGeolocations() {
    return this.currentInfo.excludedGeolocations;
  }

  set platformsSelected(selection: string[][]) {
    this.currentInfo.platformsSelected = selection;
  }

  get platformsSelected() {
    return this.currentInfo.platformsSelected;
  }

  get ages() {
    return this.currentInfo.ages;
  }

  set ages(ages: number[]) {
    this.currentInfo.ages = ages;
  }

  get genders(): number[] {
    return this.currentInfo.genders;
  }

  get includedLocales() {
    return this.currentInfo.includedLocales;
  }
  get includedRelationshipStatus() {
    return this.currentInfo.includedRelationshipStatuses;
  }

  get includedCriteriaFields(): CriteriaField[] {
    return this.creationManager.audiencesInfo.get(this.audienceType).includedCriteriaFields;
  }

  set includedCriteriaFields(fields: CriteriaField[]) {
    this.creationManager.audiencesInfo.get(this.audienceType).includedCriteriaFields = fields;
  }

  get includedCriterion() {
    return this.currentInfo.includedCriterion;
  }

  get excludedCriterion() {
    return this.currentInfo.excludedCriterion;
  }

  set excludedCriterion(criterion: FbCriteria[]) {
    this.currentInfo.excludedCriterion = criterion;
  }

  get excludedCriteriaField(): CriteriaField {
    return this.creationManager.audiencesInfo.get(this.audienceType).excludedCriteriaField;
  }

  set excludedCriteriaField(field: CriteriaField) {
    this.creationManager.audiencesInfo.get(this.audienceType).excludedCriteriaField = field;
  }

  get currentAudienceSpec(): Targeting {
    return this.creationManager.currentAudience.targeting;
  }

  get targetBenchmarkLink(): boolean {
    return this.creationManager.targetBenchmarkLink;
  }

  get env() {
    return environment;
  }

  protected readonly event = event;
}
