export abstract class TargetingUtils {
  public static readonly NUMBER_FLEX_TYPES = [
    'education_statuses', 'relationship_statuses', 'college_years', 'locales'
  ];

  private static readonly NUMBER_FLEX_LABEL: Map<string, Map<number, string>> = new Map([
    ["relationship_statuses", new Map([
      [1, "Single"],
      [2, "In relationship"],
      [3, "Married"],
      [4, "Engaged"],
      [6, "Not specified"]
    ])],
    ["education_statuses", new Map([
      [1, "High school"],
      [2, "Undergrad"],
      [3, "Alum"],
      [4, "High school grad"],
      [5, "Some college"],
      [6, "Associate degree"],
      [7, "In grad school"],
      [8, "Some grad school"],
      [9, "Master degree"],
      [10, "Professional degree"],
      [11, "Doctorate degree"],
      [12, "Unspecified"],
      [13, "Some high school"]
    ])]
  ]);

  public static getLabelForType(type: string, id: number): string {
    if (type == "college_years") {
      return "College year " + id;
    } else if (type == "locales") {
      return "Locale " + id;
    } else if (this.NUMBER_FLEX_LABEL.has(type)) {
      return this.NUMBER_FLEX_LABEL.get(type).get(id);
    } else {
      return 'Unidentified type ' + type + ' (' + id + ')';
    }
  }

  public static searchByType(type: string, query: string): any[]|undefined {
    const elements = this.NUMBER_FLEX_LABEL.get(type);
    const result = [];
    if (elements) {
      for (const [key, value] of elements) {
        if (value.toLowerCase().includes(query.toLowerCase())) {
          result.push({ key, name: value});
        }
      }
    }
    return result;
  }
}
