import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';


@Component({
  selector: 'app-sp-modal-info',
  standalone: true,
  imports: [
    MatIconModule
  ],
  templateUrl: './sp-modal-info.component.html'
})

export class SpModalInfoComponent {
  title = '';
  textContent = '';
  icon = '';

  constructor(
    public dialogRef: MatDialogRef<SpModalInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, icon: string},
  ) {
    this.title = data.title;
    this.textContent = data.content;
    this.icon = data.icon;
  }
}
